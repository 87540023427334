import React from 'react'
import SideBar from '../SideBar'

const About = () => {
  return (
    <>
        <div className='home'>
            <h3 >คู่มือการใช้</h3>
        </div>
    </>
  )
}

export default About
import React from 'react'

const ItemPoint = () => {
  return (
    <>
        <div className='home'>
            <h3 >ปักหมุดสินค้า</h3>
        </div>
    </>
  )
}

export default ItemPoint

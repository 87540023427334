import React,{useState} from 'react'
import { SideBar,Container } from '../../SideBar'
import { useNav } from '../../layout/Nav'
import DataTable from 'react-data-table-component';
import './css/addproductstock.css'
import AddStock from '../component/AddStock';

const columns = [
	{
		name: 'ลำดับ',
        sortable: true,
    cell: (row,index) => index + 1,
    grow: 0
	},
    {
		name: 'วันที่',
		selector: row => row.cateogry_code,
        sortable: true,
	},
	{
		name: 'หมายเลข',
		selector: row => row.cateogry_name,
        sortable: true,

	},
    {
		name: 'ซัพพลาย',
		selector: row => row.cateogry_name,
        sortable: true,

	},
    {
		name: 'รวมยอด',
		selector: row => row.cateogry_name,
        sortable: true,

	},
    {
		name: 'สถานะ',
		selector: row => row.cateogry_name,
        sortable: true,

	},
];

const AddProductStocks = () => {
    const { showSideBar } = useNav();
    const [addState, setAddState] = useState(false);

    const handle_state = () => {
        setAddState(!addState)
    }
  return (
    <>
    <SideBar state={true}/>
    <Container sideBar={showSideBar} className='container con-ani'>
        {addState ? 
        <>
        <AddStock handle_state={handle_state}/>
        </> :
        <>
        <div className='addStock-container'>
            <div className='header'>
                <h1>ประวัติการซื้อสินค้าเข้าร้าน</h1>
                <button onClick={handle_state}>สร้างใบคำสั่งซื้อ</button>
            </div>
            <div className='addstock-table'>
                <DataTable className='table'
                    columns={columns}
                    data={''}
                    pagination
                    subHeader
                    persistTableHead
                />
            </div>
        </div>
        </>
        }
    </Container>
    </>
  )
}

export default AddProductStocks

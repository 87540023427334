import React, { useState } from 'react'
import '../page/css/addproductstock.css'
import DataTable from 'react-data-table-component'

const columns = [
    {
		name: 'รายการ',
		selector: row => row.name,
        sortable: true,
	},
	{
		name: 'จำนวน',
		selector: row => row.stock,
        sortable: true,

	},
    {
		name: 'หน่วย',
		selector: row => row.unit,
        sortable: true,

	},
    {
		name: 'ต้นทุนต่อหน่วย',
		selector: row => row.unitCost,
        sortable: true,

	},
    {
		name: 'รวมยอด',
		selector: row => row.total,
        sortable: true,

	}
];

const listAddproduct = [];

const AddStock = ({ handle_state }) => {

    const [stateAdd, setStateAdd] = useState(false);
    

    const [list,setlist] = useState([]);
    const [id,setId] = useState('');
    const [pdt_name,setPdt_name] = useState('');
    const [pdt_unitcost,setPdt_unitcost] = useState(0);
    const [pdt_unit,setPdt_unit] = useState('');
    const [pdt_stock,setPdt_stock] = useState(0);

    const handle_AddState = () => {
        setStateAdd(!stateAdd);
    }

    const handele_Addlist = () => {
        const object = {
            id: 1,
            name: pdt_name,
            stock: pdt_stock,
            unit: pdt_unit,
            unitCost: pdt_unitcost,
            total: (pdt_stock * pdt_unitcost).toFixed(2)
        }
        listAddproduct.push(object)
        setStateAdd(false)
    }

    

    

    
  return (
    <>
    <div className='addStock-container'>
      <div className='add-header'>
        <button id='btn-1' onClick={handle_state}>กลับ</button>
        <button id='btn-2'>บันทึก</button>
        <button id='btn-3'>ยืนยัน</button>
      </div>
      <div className='add-content'>
        <div className='box1'>
            <div id='input' className='wrapper'>
                <div className='input-data'>
                    <input id='pdt_price' type='text' required/>
                    <div className='underline'></div>
                    <label>หมายเลขใบสินค้า</label>
                </div>
            </div>
            <div id='input' className='wrapper'>
                <div className='input-data'>
                    <input id='pdt_price' type='text' required/>
                    <div className='underline'></div>
                    <label>วันที่</label>
                </div>
            </div>
            <div id='input' className='wrapper'>
                <div className='input-data'>
                    <input id='pdt_price' type='text' required/>
                    <div className='underline'></div>
                    <label>Supplier</label>
                </div>
            </div>
        </div>
        <div className='box2'>
            <div className='item'>
                <div className='item-header'>
                    <h3>สินค้า</h3>
                    {!stateAdd ? 
                    <>
                        <button onClick={handle_AddState}>เพิ่ม</button>  
                    </> : 
                    <>
                        <button onClick={handele_Addlist}>ยืนยัน</button>
                        <button onClick={handle_AddState}>กลับ</button>
                    </>}
                </div>
                {stateAdd ? 
                <>
                <div className='item-add'>
                    <div id='input' className='wrapper'>
                        <div className='input-data'>
                            <input onChange={(e) => setPdt_name(e.target.value)} id='pdt_price' type='text' required/>
                            <div className='underline'></div>
                            <label>ชื่อสินค้า</label>
                        </div>
                    </div>
                    <div id='input' className='wrapper'>
                        <div className='input-data'>
                            <input onChange={(e) => setPdt_stock(e.target.value)} id='pdt_price' type='number' required/>
                            <div className='underline'></div>
                            <label>จำนวน</label>
                        </div>
                    </div>
                    <div id='input' className='wrapper'>
                        <div className='input-data'>
                            <input onChange={(e) => setPdt_unit(e.target.value)} id='pdt_price' type='text' required/>
                            <div className='underline'></div>
                            <label>หน่วย</label>
                        </div>
                    </div>
                    <div id='input' className='wrapper'>
                        <div className='input-data'>
                            <input onChange={(e) => setPdt_unitcost(e.target.value)} id='pdt_price' type='number' required/>
                            <div className='underline'></div>
                            <label>ราค่าต่อหน่วย</label>
                        </div>
                    </div>
                </div>
                </> : 
                <>
                <div className='item-added'>
                    <DataTable className='table'
                        columns={columns}
                        data={listAddproduct}
                        persistTableHead
                    />
                </div>
                </>}
            </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default AddStock
